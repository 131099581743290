import * as React from "react"
import {Page} from "../components/page";
import { JigsawPuzzle } from 'react-jigsaw-puzzle/lib';
import 'react-jigsaw-puzzle/lib/jigsaw-puzzle.css';
import cat from '../images/book-2-1.png';

export default function Jigsaw({data}) {

    return (
        <Page>
            <p>Jigsaw 2</p>
            <div className="jigsawBox">
            <JigsawPuzzle
                imageSrc={cat}
                rows={2}
                columns={2}
                onSolved={() => alert('Solved 2!')}
            />
        </div>



        </Page>
    )
}